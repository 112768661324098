var $popup,
    $popupClose,
    $cookieBtns;

var cookiesInstance,
    cookieName = 'popup-gemeente';

(function ($) {
    'use strict';

    $(function () {
        $popup          = $(".js-popup");
        $popupClose     = $(".js-close-popup");
        $cookieBtns     = $(".js-popup a");
        cookiesInstance = getCookie(cookieName);

        if (cookiesInstance) {
            $popup.addClass('is-already-closed');
        }

        $cookieBtns.on("click",function (event) {
            setPopupCookie();
        });

        $popupClose.on("click",function () {
            setPopupCookie();
        });

        function setPopupCookie() {
            setCookie(cookieName, 'Y', 7);
        }
    }); // End of DOM Ready

})(jQuery);

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
