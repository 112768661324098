var $body,
    $toggleMenu,
    $counters,
    $reviewSliders,
    $faqAnswers,
    $faqQuestions,
    $townshipMenu,
    $townshipMenuContent,
    $TownshipPage,
    $header,
    $headerOrgOffset,
    $postcodeInput,
    $houseNumberInput,
    $addressElem,
    $addressText,
    $addressSuccess,
    $addressError,
    $showMoreRows,
    $inputGroupPlus,
    $inputGroupMinus,
    $needsConfirmation,
    $popup,
    $closePopup,
    countersVisible = 0,
    previousScroll = 0;

$(document).ready(function ($) {

    console.log('%cLinku Novio', "font-size: 2em; font-family: sans-serif; color: white; background-color:#18aaff; padding:4px 8px; border-radius: 4px");

    $body = $('body');
    $toggleMenu = $(".js-menu-toggle");
    $counters = $(".js-counter");
    $reviewSliders = $('.js-slider');
    $faqQuestions = $('.js-faq-question');
    $faqAnswers = $('.js-faq-answer');
    $townshipMenu = $('.js-township-menu');
    $townshipMenuContent = $('.js-township-menu-content');
    $TownshipPage = $('.js-township-page');
    $header = $('.js-header');
    $popup = $('.js-popup')
    $headerOrgOffset = $header.offset() ? $header.offset().top : 0;
    $postcodeInput = $('#profile_form_postcode');
    $houseNumberInput = $('#profile_form_number');
    $addressElem = $('.c-postcode-check');
    $addressText = $('.c-postcode-check__address');
    $addressSuccess = $('.c-postcode-check__success');
    $addressError = $('.c-postcode-check__error');
    $showMoreRows = $('#showMoreRows');
    $inputGroupPlus = $('.c-input-group-button.plus');
    $inputGroupMinus = $('.c-input-group-button.minus');
    $needsConfirmation = $('.c-confirm');
    $closePopup = $('.js-close-popup');

    checkIfVisible();

    if(checkIfPopupCookie() === false){
        $popup.addClass('show-popup');
    }

    $toggleMenu.click(function () {
        $body.toggleClass('show-menu');
        if ($toggleMenu.hasClass('is-open')) {
            $(this).removeClass('is-open');
        } else {
            $(this).addClass('is-open');
        }
    });

    $closePopup.click(function () {
        $popup.removeClass('show-popup');
    });

    $reviewSliders.each(function (index, value) {

        tns({
            container: value,
            items: 1,
            mouseDrag: true,
            speed: 600,
            nav: false,
            autoplayButtonOutput: false,
            controls: true,
            controlsText: ["<i class=\"fas fa-arrow-left\"></i>", "<i class=\"fas fa-arrow-right\"></i>"],
            autoplay: false,
            autoHeight: false,
            autoplayHoverPause: true,
            rewind: true
        });
    });

    $faqQuestions.on('click', function () {
        if ($(this).hasClass('is-open')) {
            $faqAnswers.slideUp();
            $faqQuestions.removeClass('is-open');
        } else {
            $faqAnswers.slideUp();
            $faqQuestions.removeClass('is-open');
            $(this).next().slideToggle('fast');
            $(this).addClass('is-open');
        }
    });

    if ($TownshipPage.length > 0) {
        var slugs = [];

        $townshipMenu.each(function () {
            slugs.push($(this).attr('data-tab'));
        });
        var hash = window.location.hash.substr(1);
        if (slugs.includes(hash)) {
            $('.' + hash).css('display', 'flex');
            $('.current_' + hash).addClass('current');
        } else {
            var tab = $townshipMenu.first().data('tab');
            $('.' + tab).css('display', 'flex');
            $('.current_' + tab).addClass('current');
        }
    }

    $townshipMenu.on('click', function () {
        event.preventDefault();
        $townshipMenu.removeClass('current');
        $townshipMenuContent.hide();
        $(this).addClass('current');
        var tab = $(this).attr('data-tab');
        $('.' + tab).css('display', 'flex');
        window.location.hash = tab;
    });

    $('.js-toggle-submenu').on('click', function () {
        var $submenu = $(this).next('.js-submenu');
        $(this).toggleClass('is-open');
        $submenu.slideToggle();
    });


    $(window).on('resize', checkIfVisible);
    $(document.body).on('touchmove', checkIfVisible);

    $(window).scroll(function () {
        checkIfVisible();
        var currentScroll = $(this).scrollTop();
        if (currentScroll > $headerOrgOffset) {
            if (currentScroll > previousScroll) {
                $header.fadeOut();
            } else {
                $header.fadeIn();
                $header.addClass('fixed');
            }
        } else {
            $header.removeClass('fixed');
        }
        previousScroll = currentScroll;
    });

    /**
     * Portal (registration/change profile)
     */
    // On load
    if ($postcodeInput.val() && $houseNumberInput.val()) {
        lookupAddress();
    }

    // On change
    $postcodeInput.blur(function () {
        lookupAddress();
    });
    $houseNumberInput.blur(function () {
        lookupAddress();
    });

    var $phoneInput = document.querySelector('#profile_form_phone');
    if ($phoneInput) {
        var iti = window.intlTelInput($phoneInput, {
            utilsScript: '/assets/novio/js/utils.min.js',
            initialCountry: 'nl',
            preferredCountries: ['nl', 'be']
        });
        var handleChange = function() {
            var $phoneError = $('#phoneError');
            if (iti.isValidNumber()) {
                $phoneInput.value = iti.getNumber();
                $phoneError.html('');
            } else {
                $phoneError.html('<ul class="c-validation-errors"><li class="c-validation-error"><i class="fal fa-exclamation-circle"></i> Dit lijkt geen geldig telefoonnummer</li></ul>');
            }
        };
        $phoneInput.addEventListener('change', handleChange);
        $phoneInput.addEventListener('blur', handleChange);
    }

    /**
     * Portal (tables)
     */
    $showMoreRows.click(function (e) {
        e.preventDefault();

        // Show all rows
        $('tr').show();
        // Hide the table footer containing the 'show more' button
        $('tfoot').hide();
    });

    /**
     * Portal (input group)
     */
    $inputGroupPlus.click(function (e) {
        e.preventDefault();

        increaseDecreaseInput('increase', $(this));
    });
    $inputGroupMinus.click(function (e) {
        e.preventDefault();

        increaseDecreaseInput('decrease', $(this));
    });

    /**
     * Confirmation modal
     */
    $needsConfirmation.click(function (e) {
        e.preventDefault();

        var href = $(this).attr('href');
        var title = $(this).data('title');
        var message = $(this).data('message');
        var buttonLabel = $(this).data('button-label');
        var hideButton = Boolean($(this).data('hide-button'));
        var $confirmationModal = $('#confirmationModal');
        var $confirmationTitle = $('#confirmationModal .c-confirmation__title');
        var $confirmationMessage = $('#confirmationModal .c-confirmation__message');
        var $confirmationButton = $('#confirmationModal .c-confirmation__button');

        if (title) {
            $confirmationTitle.html(title);
        } else {
            $confirmationTitle.html('Weet je het zeker?');
        }

        if (message) {
            $confirmationMessage.html(message);
        } else {
            $confirmationMessage.html('');
        }

        if (buttonLabel) {
            $confirmationButton.html(buttonLabel);
        } else {
            $confirmationButton.html('Ja');
        }

        $confirmationModal.modal('show');
        $confirmationButton.show();

        if (hideButton === true) {
            $confirmationButton.hide();
        } else {
            $confirmationModal.find('.c-confirmation__button').click(function () {
                window.location.href = href;
            });
        }
    });
});

function checkIfVisible() {
    if ($counters.length && countersVisible < $counters.length) {
        $counters.each(function () {
            if ($(this).isInViewport() && !$(this).hasClass('is-visible')) {
                countersVisible++;
                $(this).addClass('is-visible');
                countUp($(this).find('.js-count'));
            }
        });
    }
}

function countUp($element) {
    $element.addClass('is-counting');
    var countTo = $element.attr('data-count');
    countTo = countTo.replace(/[^0-9]/, '');
    countTo = countTo.replace('.', '');

    $({countNum: $element.text()}).delay(300).animate({
        countNum: countTo
    }, {
        duration: 3000,
        easing: 'linear',
        step: function () {
            $element.text(format(Math.floor(this.countNum)));
        },
        complete: function () {
            $element.text(format(this.countNum));
        }
    });
}

function format(nStr) {
    nStr += '';
    x = nStr.split(',');
    x1 = x[0];
    x2 = x.length > 1 ? ',' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
}

$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementTop > viewportTop && elementBottom < viewportBottom;
};

function lookupAddress() {
    var postcode = $postcodeInput.val();
    var houseNumber = $houseNumberInput.val();

    if (!postcode || !houseNumber) {
        return;
    }

    $.post('/lookup-address', {postcode: postcode, houseNumber: houseNumber}, function (data) {
        if (data.error) {
            showWrongAddress(data);
            return;
        }

        $addressSuccess.show();
        $addressError.hide();
        $addressElem.show();
        //noinspection JSUnresolvedVariable
        $addressText.html(data.address.street + ' ' + $houseNumberInput.val() + '<br>' + data.address.postcode + ' ' + data.address.city);
    }).fail(function (error) {
        showWrongAddress(error);
    });
}

function showWrongAddress(error) {
    console.log(error);
    $addressError.html('<span class="c-validation-error"><i class="fal fa-exclamation-circle"></i> Deze postcode/huisnummercombinatie is onbekend.</span>');
    $addressSuccess.hide();
    $addressError.show();
    $addressElem.hide();
    $addressText.html('');
}

function increaseDecreaseInput(action, element) {
    var input = element.parent().parent().children($('.c-input'));
    var currentAmount = Number(input.val());

    if (action === 'decrease' && Number(input.attr('min')) === 0 && currentAmount === 0) {
        return;
    }

    if (action === 'increase') {
        input.val(currentAmount + 1);
    } else if (action === 'decrease') {
        input.val(currentAmount - 1);
    }
}

function checkIfPopupCookie(){
    if (window.localStorage) {
        // Get the expiration date of the previous popup.
        var popupCookie = localStorage.getItem( 'wijkheldenPopupShown' );
        if (popupCookie > new Date()) {
            return true;
        }
        else{
            return false;
        }
    }
}

function setPopupCookie(){
    // Store the expiration date of the current popup in localStorage.
    var expires = new Date();
    expires = expires.setHours(expires.getHours() + 24);
    localStorage.setItem( 'wijkheldenPopupShown', expires );
}
